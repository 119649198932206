<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Help Info
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            Welcome to the TradeWinds support page. If you can’t find what you’re looking for here, remember that you can always contact us directly by <a href="mailto:help@tradewindsnews.com">email</a> or <a href="https://www.tradewindsnews.com/contactus/">telephone.</a>
          </p>
          <p>
            Do you  have any questions about subscription? <a href="https://www.tradewindsnews.com/mytw/faq/">Check our FAQ</a>
          </p>
          <p>
            <strong>Mobile Apps</strong>
          </p>
          <p>
            TradeWinds is available on the go with mobile devices running on both iOS or Android. The apps are free to download from the <a href="https://itunes.apple.com/us/app/tradewinds/id486335484?ls=1&mt=8">App Store</a> and <a href="https://market.android.com/details?id=com.tradewindsnews.reader&feature=search_result#?t=W251bGwsMSwxLDEsImNvbS50cmFkZXdpbmRzbmV3cy5yZWFkZXIiXQ">Google Play</a>.
          </p>
          <p>
            <strong>Download the app</strong>
          </p>
          <p>
            <a href="https://itunes.apple.com/us/app/tradewinds/id486335484?ls=1&mt=8"><FontAwesomeIcon :icon="appleIcon"/>&nbsp;Download</a>
          </p>
          <p>
            <a href="https://market.android.com/details?id=com.tradewindsnews.reader&feature=search_result#?t=W251bGwsMSwxLDEsImNvbS50cmFkZXdpbmRzbmV3cy5yZWFkZXIiXQ">
              <FontAwesomeIcon :icon="androidIcon"/>&nbsp;Download
            </a>
          </p>
          <p>
            <strong>Support</strong>
          </p>
          <p>
            If you have any problems or questions contact us at <a href="mailto:help@tradewindsnews.com">help@tradewindsnews.com</a> or <a href="mailto:sales@tradewindsnews.com">sales@tradewindsnews.com</a>
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';

export default {
  name: 'help-page',
  components: {
    FontAwesomeIcon
  },
  extends: Page,
  data() {
    return {
      appleIcon: faApple,
      androidIcon: faAndroid
    };
  }
};
</script>
